import { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Page, Tabs, BlockStack, Divider, Card, EmptyState } from '@shopify/polaris';
import { StarFilledIcon, PlusIcon } from '@shopify/polaris-icons';
import CustomerNew from './customer.new';
import SkeletonLoading from 'components/skeletonPageLoading';
import CustomerGeneralDetail from './customer_detail/customer.general.information';
import CustomerOtherInformation from './customer_detail/customer.other.information';
import UserAchievement from 'components/user_achivement';
import UserActivity from 'components/user_activity';
import CustomerEditInformation from './customer_detail/customer.edit';
import ChangeCustomerPassword from './customer_detail/customer.password';
import CustomerLoginHistory from './customer_detail/customer.login.history';
import ActivityTracing from 'components/activityTracing';
import { Helmet } from 'react-helmet-async';
import UserFinance from './customer_detail/userFinance';
import CustomerAddressBook from './customer_detail/customer.address.book';
import { useGetCustomer, useUpdateCustomer } from 'queries/customer.query';
import Capacity from 'components/Capacity';
import { useAuth } from 'context/AuthContext';
import CustomerWalletSumarize from './customer_detail/customer.wallet';
import CustomerReferrer from './customer_detail/customer.referrer';
export default function CustomerView() {
  let {
    customer_id
  } = useParams();
  let {
    hash
  } = useLocation();
  const {
    data: entity,
    isLoading: loading,
    refetch: getEntity,
    isError
  } = useGetCustomer(customer_id);
  const {
    mutateAsync: updateEntity,
    isPending
  } = useUpdateCustomer();
  const history = useNavigate();
  const {
    currentUserCan
  } = useAuth();
  const HIDE_AFFILIATE_MODE = process.env.REACT_APP_HIDE_AFFILIATE === 'true';

  /**
   * New model
   */

  const [newModelactive, setNewModelactive] = useState(false);
  const toggleThisCustomerIsVip = useCallback(async entity => {
    if (!entity) return;
    let newValue = !entity?.customer_to_user?.is_vip;
    try {
      await updateEntity({
        user_id: customer_id,
        is_vip: Number(newValue || 0)
      });
    } catch (e) {}
  }, []);
  const [tabselected, setTabselected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => {
    window.location.hash = '#tab-' + selectedTabIndex;
  }, []);
  useEffect(() => {
    if (hash) {
      setTabselected(Number(String(hash || ' ').replace('#tab-', '')));
    } else {
      setTabselected(0);
    }
  }, [hash]);
  const tabs = [...[{
    id: 'tab_0',
    content: 'Thông tin chung',
    panelID: 'general'
  }, ...[currentUserCan(['view_affiliates']) && {
    id: 'tab_1',
    content: 'Thành tựu',
    panelID: 'achived'
  }], {
    id: 'tab_2',
    content: 'Tương tác',
    panelID: 'user_react'
  }, {
    id: 'tab_3',
    content: 'Lịch sử',
    panelID: 'activity_trace'
  }], ...[currentUserCan(['view_affiliates']) && !HIDE_AFFILIATE_MODE && {
    id: 'tab_4',
    content: 'Affiliate',
    panelID: 'referrer_trace'
  }], ...[{
    id: 'tab_5',
    content: 'Chỉnh sửa tài khoản',
    panelID: 'edit_user_account'
  }, ...[currentUserCan(['view_affiliates']) && {
    id: 'tab_6',
    content: 'Thông tin thanh toán',
    panelID: 'user_payment_information'
  }], {
    id: 'tab_7',
    content: 'Mật khẩu',
    panelID: 'user_password'
  }, {
    id: 'tab_8',
    content: 'Lịch sử đăng nhập',
    panelID: 'user_login_history'
  }, {
    id: 'tab_9',
    content: 'Sổ địa chỉ',
    panelID: 'user_address_book'
  }]];
  return <>
      <Helmet>
        <title>{entity?.display_name}</title>
      </Helmet>

      {loading ? <SkeletonLoading /> : entity ? <Page title={''} backAction={{
      content: 'Danh sách khách hàng',
      onAction: () => history(-1)
    }} secondaryActions={[{
      content: entity?.customer_to_user?.is_vip ? 'Bỏ khỏi VIP' : 'Đánh dấu VIP',
      icon: StarFilledIcon,
      disabled: !currentUserCan(['manage_customers', 'edit_customers']),
      onAction: () => {
        toggleThisCustomerIsVip(entity);
      }
    }, {
      content: 'Thêm khách',
      icon: PlusIcon,
      disabled: !currentUserCan(['manage_customers', 'edit_customers']),
      onAction: () => {
        setNewModelactive(true);
      }
    }]}>
          <BlockStack gap="400">
            <CustomerGeneralDetail entity={entity} />
            {/** ví của khách  */}
            <CustomerWalletSumarize customer_id={entity?.user_id} />
            {/* {!HIDE_AFFILIATE_MODE && (
              <Capacity current_user_can={'view_customer_wallet'}>
                <CustomerWalletSumarize customer_id={entity?.user_id} />
              </Capacity>
             )} */}

            <Divider />

            <div className="custom_tabs" style={{
          margin: '0 -15px'
        }}>
              <Tabs tabs={tabs} selected={tabselected} onSelect={handleTabChange}></Tabs>
              <div style={{
            margin: '15px'
          }}>
                {tabs[tabselected].panelID === 'general' && <CustomerOtherInformation entity={entity} />}
                <Capacity current_user_can={['view_affiliates']} onFail={<>Bạn không có quyền chỉnh sửa khách hàng.</>}>
                  {tabs[tabselected].panelID === 'achived' && <UserAchievement user_id={entity?.user_id} showList />}
                </Capacity>
                {tabs[tabselected].panelID === 'user_react' && <UserActivity objectId={customer_id} title="Tương tác" />}
                {tabs[tabselected].panelID === 'activity_trace' && <ActivityTracing userID={customer_id} />}
                <Capacity current_user_can={['manage_customers', 'edit_customers']} onFail={<>Bạn không có quyền chỉnh sửa khách hàng.</>}>
                  {tabs[tabselected].panelID === 'edit_user_account' && <CustomerEditInformation onClose={() => getEntity()} entity={entity} />}
                  {tabs[tabselected].panelID === 'user_password' && <ChangeCustomerPassword user_data={entity} onClose={() => getEntity()} />}
                  {tabs[tabselected].panelID === 'user_payment_information' && <UserFinance current_user_id={entity?.user_id} />}
                </Capacity>
                {tabs[tabselected].panelID === 'user_login_history' && <CustomerLoginHistory user_id={customer_id} />}
                {tabs[tabselected].panelID === 'user_address_book' && <CustomerAddressBook user_id={customer_id} />}
                {tabs[tabselected].panelID === 'referrer_trace' && <CustomerReferrer user_id={customer_id} />}
              </div>
            </div>
          </BlockStack>
        </Page> : <>
          <EmptyState heading="Không tìm thấy khách hàng này" action={{
        content: 'Về danh sách',
        onAction: () => history('/customer')
      }} image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
            <p>Hoặc là do bạn không có quyền truy cập hoặc là khách hàng này đã bị xóa.</p>
          </EmptyState>
        </>}

      <CustomerNew onClose={() => setNewModelactive(false)} show={newModelactive} />

      <br />
      <br />
      <br />
    </>;
}