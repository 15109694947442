import { FormLayout, TextField, Text, Form, ChoiceList, Modal, OptionList, Icon } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import helpers from '../../helpers';
import { lengthLessThan, lengthMoreThan, useField, useForm } from '@shopify/react-form';
import orderDeliverType from '../order/order.delivery.json';
import { TypedLogistic_service, useCreateLogisticService, useMutateGetLogistic, useUpdateLogistic } from 'queries/logistic_service.query';
import { logistic_api_require_fields } from 'queries/logistic_service.query';
export default function LogisticModal({
  show,
  logistic_id,
  onClose
}) {
  const {
    data: logisticData,
    mutate: getEntity
  } = useMutateGetLogistic();
  const {
    isPending: updating,
    isSuccess: updateSuccess,
    mutate: updateEntity
  } = useUpdateLogistic();
  const {
    mutate: createEntity,
    isSuccess: createSuccess
  } = useCreateLogisticService();
  const [entity, setEntity] = useState<TypedLogistic_service>(null);
  useEffect(() => {
    setEntity(logisticData);
  }, [logisticData]);

  /** API yêu cầu trường nào? */
  const [selectedRequireFieldForAPI, setSelectedRequireFieldForAPI] = useState<string[]>(['']);
  const handleRequireFieldChange = useCallback((value: string[]) => {
    setSelectedRequireFieldForAPI(value);
    useFields.logistic_api_require_field.onChange(value.join(','));
  }, []);
  const [deliverySelected, setDeliverySelected] = useState<string[]>([]);
  const onCloseCallback = useCallback(() => {
    onClose?.call(this, 'close');
  }, []);
  useEffect(() => {
    if (updateSuccess) onCloseCallback();
  }, [updateSuccess]);
  useEffect(() => {
    if (createSuccess) onCloseCallback();
  }, [createSuccess]);
  useEffect(() => {
    if (logistic_id) getEntity(logistic_id);else setEntity(null);
  }, [logistic_id]);
  useEffect(() => {
    if (!entity) return;
    if (entity?.logistic_delivery_method) setDeliverySelected(entity?.logistic_delivery_method?.split(','));
    if (entity?.logistic_api_require_field) setSelectedRequireFieldForAPI(entity?.logistic_api_require_field?.split(','));
  }, [entity]);
  const useFields = {
    logistic_name: useField<string>({
      value: entity?.logistic_name ?? '',
      validates: [lengthLessThan(250, 'No more than 250 characters.'), lengthMoreThan(1, 'No shorter than 1 characters.'), inputValue => {
        if (inputValue.length < 2) {
          return 'Your title is too short, or it is empty.';
        }
      }]
    }),
    logistic_description: useField<string>({
      value: entity?.logistic_description ?? '',
      validates: [lengthLessThan(250, 'No more than 250 characters.'), lengthMoreThan(2, 'No shorter than 2 characters.')]
    }),
    logistic_delivery_method: useField<string>({
      value: entity?.logistic_delivery_method ?? '',
      validates: [lengthLessThan(250, 'No more than 250 characters.'), lengthMoreThan(2, 'No shorter than 2 characters.')]
    }),
    logistic_api_require_field: useField<string>({
      value: entity?.logistic_api_require_field ?? '',
      validates: [lengthLessThan(250, 'No more than 150 characters.')]
    }),
    logistic_status: useField<string>({
      value: String(entity?.logistic_status || 0),
      validates: []
    }),
    logistic_fee: useField<string>({
      value: entity?.logistic_fee > 0 ? 'fixed' : String(entity?.logistic_fee || -1),
      validates: []
    }),
    logistic_fee_value: useField<string>({
      value: String(entity?.logistic_fee || '0'),
      validates: []
    }),
    logistic_insurance_fee_mode: useField<string>({
      value: entity?.logistic_insurance_fee?.mode || 'auto',
      validates: []
    }),
    logistic_insurance_rules: useField<string>({
      value: entity?.logistic_insurance_fee?.rules,
      validates: [inputVal => {
        if (inputVal) {
          let breakString = String(inputVal).split('|');
          if (breakString) for (let rule of breakString) {
            if (String(rule).split(',').filter(helpers.isNumber).length < 3) return 'Phải là một chuỗi hợp lệ, gồm chỉ chữ số và dấu phẩy. Ví dụ: 0,2,300';
          }
        }
      }]
    }),
    module: useField<string>({
      value: entity ? entity.module : '',
      validates: [inputValue => {
        if (inputValue && helpers.isUTF8(inputValue)) {
          return 'Không dùng mã UTF8 trong tên module';
        }
      }]
    })
  };
  const logistic_delivery_method_fired = useFields.logistic_delivery_method;
  const logisticStatusOption = useFields.logistic_status;
  const onChangeDeliveryMethod = useCallback((val: string[]) => {
    setDeliverySelected(val);
    logistic_delivery_method_fired.dirty = true;
  }, [logistic_delivery_method_fired]);
  const orderDeliveryList = [];
  for (let x in orderDeliverType) {
    let name = orderDeliverType[x];
    orderDeliveryList.push({
      label: `${name}`,
      value: `${name}`
    });
  }
  const {
    fields,
    submit,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!logistic_id) {
          // create new
          createEntity({
            logistic_name: values.logistic_name,
            module: values.module,
            logistic_description: values.logistic_description,
            logistic_status: Number(values.logistic_status),
            logistic_delivery_method: deliverySelected.join(','),
            logistic_api_require_field: values.logistic_api_require_field,
            logistic_fee: values.logistic_fee === 'fixed' ? Number(values.logistic_fee_value) : Number(values.logistic_fee),
            logistic_insurance_fee: {
              mode: values.logistic_insurance_fee_mode,
              rules: values.logistic_insurance_rules ? String(values.logistic_insurance_rules) : ''
            }
          });
        } else {
          updateEntity({
            logistic_id: logistic_id,
            logistic_name: values.logistic_name,
            module: values.module,
            logistic_description: values.logistic_description,
            logistic_status: Number(values.logistic_status),
            logistic_delivery_method: deliverySelected.join(','),
            logistic_api_require_field: values.logistic_api_require_field || null,
            logistic_fee: values.logistic_fee === 'fixed' ? Number(values.logistic_fee_value) : Number(values.logistic_fee),
            logistic_insurance_fee: {
              mode: values.logistic_insurance_fee_mode,
              rules: values.logistic_insurance_rules ? String(values.logistic_insurance_rules) : ''
            }
          });
        }
      } catch (e: any) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          useFields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    Userreset();
  }, [show, logistic_id]);
  return <>
      <Modal open={show} title={'Tạo hoặc cập nhật đơn vị vận chuyển'} onClose={() => onCloseCallback()} primaryAction={{
      content: logistic_id ? 'Cập nhật' : 'Tạo & đóng',
      disabled: !dirty,
      loading: updating,
      onAction: submit
    }} secondaryActions={[{
      content: 'Đóng',
      onAction: () => onCloseCallback()
    }]}>
        <Modal.Section>
          <Form onSubmit={submit}>
            <FormLayout>
              <TextField autoComplete="off" autoFocus requiredIndicator label="Tên dịch vụ" {...fields.logistic_name} />
              <TextField autoComplete="off" label="Tên Module" helpText="Mỗi một nhà vận chuyển có một tên mã Module mà server hỗ trợ. Vui lòng liên hệ tới quản trị viên để bổ sung vào danh sách." {...fields.module} />
              <TextField autoComplete="off" maxLength={250} label="Mô tả" {...fields.logistic_description} showCharacterCount={true} multiline={2} />
              <Text as="p" variant="headingSm">
                Trường API yêu cầu
              </Text>
              <ChoiceList allowMultiple title={''} choices={logistic_api_require_fields.map((el, index) => {
              return {
                value: el,
                label: el
              };
            })} selected={selectedRequireFieldForAPI} onChange={handleRequireFieldChange} />

              <Text as="p" variant="headingSm">
                Phương thức giao nhận
              </Text>
              <OptionList title="" onChange={onChangeDeliveryMethod} options={orderDeliveryList} selected={deliverySelected} allowMultiple />

              <Text as="p" variant="headingSm">
                Cách tính phí vận chuyển
              </Text>
              <ChoiceList title="" onChange={val => useFields.logistic_fee.onChange(val.pop())} choices={[{
              label: 'Tự động',
              value: '-1'
            }, {
              label: 'Miễn phí',
              value: '0'
            }, {
              label: 'Phí cố định',
              value: 'fixed',
              renderChildren: (isSeleted: boolean) => {
                return isSeleted && <TextField autoComplete="off" label="Phí cố định cho mỗi lần vận chuyển" {...useFields.logistic_fee_value} />;
              }
            }]} selected={[useFields.logistic_fee.value]} />

              <div>
                <Text as="p" variant="headingSm">
                  Khai bảo hiểm hàng khóa
                </Text>
                <Text as="p" variant="bodySm" tone="subdued">
                  Giá vận chuyển đôi khi bị ảnh hưởng bởi tiền bảo hiểm, trong trường hợp bạn muốn giảm bảo hiểm và chịu rủi ro, có thể cài đặt giá
                  trị tối đa tại đây.
                </Text>
                <br />
                <ChoiceList title="" onChange={val => useFields.logistic_insurance_fee_mode.onChange(val[0])} choices={[{
                label: 'Tự động',
                value: 'auto'
              }, {
                label: 'Quy tắc riêng',
                value: 'custom',
                renderChildren: (isSeleted: boolean) => {
                  return isSeleted && <TextField autoComplete="off" label="" helpText="Quy tắc: min,max,declare_value|min,max,declare_value, nhiều giá trị phân tách bởi dấu suộc đứng." {...useFields.logistic_insurance_rules} />;
                }
              }]} selected={[useFields.logistic_insurance_fee_mode.value]} />
              </div>

              <Text as="p" variant="headingSm">
                Tình trạng sử dụng
              </Text>

              <ChoiceList title="" choices={[{
              label: 'Tắt',
              value: '0',
              helpText: 'Tạm thời ngừng sử dụng dịch vụ vận chuyển này'
            }, {
              label: 'Bật',
              value: '1',
              helpText: 'Tất cả mọi người đều xem được và thêm được vào đơn hàng.'
            }]} selected={[logisticStatusOption.value]} onChange={([value]) => logisticStatusOption.onChange(value)} />
            </FormLayout>
          </Form>
        </Modal.Section>
      </Modal>
    </>;
}