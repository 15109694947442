import { useCallback, useEffect, useState } from 'react';
import { Page, Text, TextField, ContextualSaveBar, Checkbox, BlockStack, Select, Card, Tabs } from '@shopify/polaris';
import { useField, useForm } from '@shopify/react-form';
import helpers from '../../helpers';
import { useUpdateSetting } from 'queries/setting.query';
import __ from 'languages/index';
import { Helmet } from 'react-helmet-async';
import { useNotification } from 'context/NotificationContext';
import { useParams } from 'react-router-dom';
import UserRole from './user_role_list';
import CapacityGroup from './capacity_group';
import { useSettings } from 'context/SettingsContext';
export default function AccountSetting() {
  const HIDE_AFFILIATE_MODE = process.env.REACT_APP_HIDE_AFFILIATE === 'true';
  const {
    addNotification
  } = useNotification();
  const {
    settings: entities
  } = useSettings();
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();
  const useFields = {
    welcome_new_user: useField<number>({
      value: Number(entities?.welcome_new_user ?? 1),
      validates: []
    }),
    must_validated_account: useField<number>({
      value: Number(entities?.must_validated_account ?? 0),
      validates: []
    }),
    must_add_referrer: useField<number>({
      value: Number(entities?.must_add_referrer ?? 0),
      validates: []
    }),
    deactive_account_after_day: useField<number>({
      value: Number(entities?.deactive_account_after_day ?? '0'),
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          must_validated_account: values.must_validated_account,
          welcome_new_user: values.welcome_new_user,
          must_add_referrer: values.must_add_referrer,
          deactive_account_after_day: values.deactive_account_after_day
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /**
   * Notification
   */

  useEffect(() => {
    if (error) {
      addNotification('error', error.message);
    }
  }, [error]);
  useEffect(() => {
    if (updateSuccess) addNotification('info', __('updated_successfully'));
  }, [updateSuccess]);

  /** Tabs */
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => setSelected(selectedTabIndex), []);
  const tabs = [{
    id: 'general',
    content: 'Cài đặt chung'
  }, {
    id: 'user_role',
    content: 'Vai trò'
  }, {
    id: 'capacity',
    content: 'Phân quyền'
  }];
  useEffect(() => {
    let id = tabs[selected].id;
    let pathname = window.location.pathname;
    if (pathname !== `/account_setting/${id}`) {
      window.history.replaceState(null, 'Account_setting', '/account_setting/' + id);
    }
  }, [selected]);

  /** Lấy từ URL */
  let {
    slug = 'general'
  } = useParams();
  useEffect(() => {
    let findIDx = tabs.findIndex(el => el.id === slug);
    if (findIDx) {
      setSelected(findIDx);
    }
  }, [slug]);
  return <>
      <Helmet>
        <title>Cài đặt tài khoản</title>
      </Helmet>

      <Page title="Cài đặt tài khoản">
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>

        {selected === 0 && <Card>
            <BlockStack gap="400">
              <Checkbox label="Gửi email chào mừng cho khách hàng khi đăng ký mới nếu họ đăng ký bằng Email" checked={fields.welcome_new_user.value === 1} onChange={e => fields.welcome_new_user.onChange(Number(e))} />

              <Checkbox label="Bắt buộc xác minh tài khoản khi đăng ký" helpText="Khi đăng ký mới tài khoản bằng hình thức nào, đều nên xác minh, tuy nhiên tùy vào chiến dịch của bạn" checked={fields.must_validated_account.value === 1} onChange={e => fields.must_validated_account.onChange(Number(e))} />

              {!HIDE_AFFILIATE_MODE && <Checkbox label="Bắt buộc thêm mã giới thiệu khi đăng ký" helpText="Khi đăng ký mới tài khoản bằng hình thức nào, đều sẽ yêu cầu thêm mã giới thiệu." checked={fields.must_add_referrer.value === 1} onChange={e => fields.must_add_referrer.onChange(Number(e))} />}

              <TextField label="Tự động khóa tài khoản sau số ngày không đăng nhập" suffix={'Ngày'} autoComplete="off" value={helpers.formatNumber(fields.deactive_account_after_day.value)} onChange={e => fields.deactive_account_after_day.onChange(helpers.parseNumeric(e))} />
              <Text as="p" tone="subdued">
                Sau số ngày quy định, tài khoản sẽ bị tắt và tự động mở khi đăng nhập lại.
              </Text>
            </BlockStack>
          </Card>}

        {selected === 1 && <UserRole />}
        {selected === 2 && <CapacityGroup />}

        {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
        onAction: () => submit(),
        loading: updating
      }} discardAction={{
        onAction: () => resetForm()
      }} />}
      </Page>
      <br />
      <br />
    </>;
}