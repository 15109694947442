import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Card, DataTable, Link } from '@shopify/polaris';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import { useGetGameLuckyList, useGameLuckyWheelMarkAsSent } from 'queries/game.query';
import helpers from 'helpers/index';
import SimpleFilter from 'components/simpleFilter';
import { useNotification } from 'context/NotificationContext';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useLocation } from 'react-router-dom';
import __ from 'languages/index';
export default function WinnerList() {
  const [totalItems, setTotalItems] = useState(0);
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: markAsSentAGift,
    isSuccess: markAsSentSuccess
  } = useGameLuckyWheelMarkAsSent();
  useEffect(() => {
    if (markAsSentSuccess) {
      addNotification('info', 'Đã đánh dấu là chuyển quà thành công.');
    }
  }, [markAsSentSuccess]);
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  const {
    data,
    refetch: getEntities
  } = useGetGameLuckyList(mainQuery);

  /** LISTING */

  const [topTenData, setTopTenData] = useState([]);
  useEffect(() => {
    try {
      let {
        body,
        totalItems
      } = data;
      setTopTenData(body);
      setTotalItems(totalItems);
    } catch (e) {}
  }, [data]);
  const reduceRequest = useCallback(mainQuery => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) window.history.replaceState(null, 'Game', '/game/lucky_wheel' + buildURLSearch);
    getEntities();
    setMainQuery(mainQuery);
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const markAsSentAGiftCallback = useCallback(async (ID: string) => {
    const confirmed = await showDeleteConfirm('Đánh dấu đã chuyển quà?', 'Hành động này sẽ không thể hoàn tác.');
    if (confirmed) {
      try {
        await markAsSentAGift(ID);
      } catch (e) {}
    }
  }, []);
  return <>
      <Card padding={'0'}>
        <SimpleFilter onCallback={filterCallback} options={[{
        field: 'game_lucky_wheel_present.present_type',
        label: 'Quà vật lý',
        value: 'normal'
      }, {
        field: 'game_lucky_wheel_present.present_type',
        label: 'Loại khác',
        value: ''
      }]} loading={false} sortField={[]} />
        <DataTable columnContentTypes={['text', 'numeric', 'numeric', 'numeric']} headings={['Người chơi', 'Quà', 'Loại quà', 'Chuyển quà?', 'Ngày quay được']} rows={Array.isArray(topTenData) && topTenData.length > 0 ? topTenData.map((element, index) => {
        return [<Link url={'/users/view/' + element.user_id}>{element.user.display_name}</Link>, element?.game_lucky_wheel_present?.present_name, __('present_type_' + element?.game_lucky_wheel_present?.present_type), element?.is_sent === 1 ? <Badge tone="success">Đã chuyển</Badge> : <Link onClick={() => markAsSentAGiftCallback(element?.id)}>Đánh dấu đã chuyển</Link>, dateandtime.format(new Date(Number(element.createdAt)), 'DD-MM-YYYY')];
      }) : []} />
      </Card>

      <br />
      <br />
      {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery?.page} pageSize={mainQuery?.limit} onChange={onChangePagination} /> : null}
      <br />
      <br />
    </>;
}