import { HomeIcon, SettingsIcon, BlogIcon, FolderIcon, MenuHorizontalIcon, NotificationIcon, SmileyJoyIcon, GiftCardFilledIcon, DeliveryFilledIcon, ProductIcon, ChartVerticalFilledIcon, PersonIcon, IncomingIcon, ProductReferenceIcon, PackageIcon, CartDownIcon, GamesIcon, CursorBannerIcon, CalculatorIcon, ShieldCheckMarkIcon, AtmWithdrawalIcon, TargetIcon, ConnectIcon, CashDollarIcon, StatusActiveIcon, StoreManagedIcon, PersonalizedTextIcon, AffiliateIcon, LockFilledIcon, MetaobjectIcon, EmailIcon, PhoneIcon, MobileIcon, EmailFollowUpIcon, EmailNewsletterIcon, StarFilledIcon, WalletIcon, AppsIcon, CollectionFeaturedIcon, StarIcon, ButtonPressIcon } from '@shopify/polaris-icons';
import { Navigation, Text, BlockStack, Badge } from '@shopify/polaris';
import { useLocation } from 'react-router-dom';
import { default as packageInformation } from '../../package.json';
import helpers from 'helpers/index';
import Capacity from 'components/Capacity';
import { useAuth } from 'context/AuthContext';
import __helpers from 'helpers/index';
import { useSettings } from 'context/SettingsContext';
const HIDE_AFFILIATE_MODE = process.env.REACT_APP_HIDE_AFFILIATE === 'true';
const generalMenu: any[] = [{
  url: '/',
  label: 'Trang chủ',
  icon: HomeIcon,
  exactMatch: true,
  permissions: [],
  // Quyền cần để truy cập mục này
  subNavigationItems: [...[{
    url: '/profile',
    label: 'Trang cá nhân',
    permissions: [] // Quyền cần để truy cập mục con này
  }], ...(!HIDE_AFFILIATE_MODE ? [{
    url: '/social',
    label: 'Thư viện',
    permissions: ['manage_social', 'create_social'] // Quyền cần để truy cập mục con này
  }, {
    url: '/my-wallet',
    label: 'Ví của tôi',
    permissions: ['view_own_wallet'] // Quyền cần để truy cập mục con này
  }, {
    url: '/my_referrer',
    label: 'Giới thiệu kiếm tiền',
    permissions: ['view_own_affiliate'] // Quyền cần để truy cập mục con này
  }] : [])]
}];
const saleMenus = [{
  url: '/customer',
  label: 'Khách hàng',
  icon: PersonIcon,
  permissions: ['manage_customers', 'view_customers', 'edit_customers'] // Quyền cần để truy cập mục này
}, {
  url: '/phonelog',
  label: 'Cuộc gọi',
  icon: PhoneIcon,
  permissions: ['manage_customers', 'manage_phonelogs'] // Quyền cần để truy cập mục này
}, {
  url: '/shopping_cart',
  label: 'Giỏ hàng',
  icon: CartDownIcon,
  permissions: ['view_all_shopping_cart'] // Quyền cần để truy cập mục này
}, {
  url: '/wishlist',
  label: 'Sản phẩm yêu thích',
  icon: StatusActiveIcon,
  permissions: ['view_all_wishlist'] // Quyền cần để truy cập mục này
}, {
  url: '/order',
  label: 'Đơn hàng',
  icon: ProductIcon,
  permissions: ['manage_orders', 'view_orders', 'edit_orders'] // Quyền cần để truy cập mục này
}];
let stockMenus: any[] = [{
  url: '/product',
  label: 'Sản phẩm',
  icon: ProductReferenceIcon,
  permissions: ['manage_products', 'view_products', 'edit_products'],
  // Quyền cần để truy cập mục này
  subNavigationItems: [{
    url: '/product',
    label: 'Tất cả',
    icon: MenuHorizontalIcon,
    permissions: ['view_products', 'edit_products'] // Quyền cần để truy cập mục con này
  }, {
    url: '/product_category',
    label: 'Danh mục',
    icon: MenuHorizontalIcon,
    permissions: ['manage_product_categories'] // Quyền cần để truy cập mục con này
  }, {
    url: '/product_collection',
    label: 'Bộ sưu tập',
    icon: MenuHorizontalIcon,
    permissions: ['manage_product_collections'] // Quyền cần để truy cập mục con này
  }, {
    url: '/product_brand',
    label: 'Nhãn hiệu',
    icon: MenuHorizontalIcon,
    permissions: ['manage_product_brands'] // Quyền cần để truy cập mục con này
  }]
}, {
  url: '/stock',
  label: 'Kho hàng',
  icon: StoreManagedIcon,
  permissions: ['manage_stocks'] // Quyền cần để truy cập mục này
}, {
  url: '/logistic',
  label: 'Vận chuyển',
  icon: DeliveryFilledIcon,
  permissions: ['manage_logistics'] // Quyền cần để truy cập mục này
}, {
  url: '/voucher',
  label: 'Khuyến mại',
  icon: CollectionFeaturedIcon,
  permissions: ['manage_vouchers'] // Quyền cần để truy cập mục này
}];
if (!HIDE_AFFILIATE_MODE) {
  stockMenus = [...stockMenus, ...[{
    url: '/gift',
    label: 'Đổi quà(redeem)',
    icon: GiftCardFilledIcon,
    permissions: ['manage_gifts'],
    // Quyền cần để truy cập mục này
    subNavigationItems: [{
      url: '/wallet_code',
      label: 'Wallet code',
      icon: MenuHorizontalIcon,
      permissions: ['manage_wallet', 'manage_wallet_codes'] // Quyền cần để truy cập mục con này
    }, {
      url: '/gift',
      label: 'Quà',
      icon: MenuHorizontalIcon,
      permissions: ['view_gifts'] // Quyền cần để truy cập mục con này
    }, {
      url: '/redeem_request',
      label: 'Yêu cầu đổi quà',
      icon: MenuHorizontalIcon,
      permissions: ['manage_redeem_requests'] // Quyền cần để truy cập mục con này
    }]
  }]];
}
let supporterMenus: any[] = [...[{
  url: '/contactform',
  label: 'Contact Form',
  icon: PersonalizedTextIcon,
  permissions: ['manage_contact_forms'] // Quyền cần để truy cập mục này
}], ...(!HIDE_AFFILIATE_MODE ? [{
  url: '/transactions',
  label: 'Giao dịch',
  icon: CashDollarIcon,
  permissions: ['manage_transactions', 'view_transactions'] // Quyền cần để truy cập mục này
}, {
  url: '/affiliate',
  label: 'Affiliate',
  icon: AffiliateIcon,
  permissions: ['manage_affiliates', 'view_affiliates'] // Quyền cần để truy cập mục này
}, {
  url: '/user_verified',
  label: 'Yêu cầu chứng thực',
  icon: ShieldCheckMarkIcon,
  permissions: ['manage_user_verified'],
  // Quyền cần để truy cập mục này
  badge: <Badge tone="magic">---</Badge>
}, {
  url: '/withdraw',
  label: 'Yêu cầu rút tiền',
  icon: AtmWithdrawalIcon,
  permissions: ['manage_withdraw'],
  // Quyền cần để truy cập mục này
  badge: <Badge tone="magic">---</Badge>
}, {
  url: '/redeem_request',
  label: 'Yêu cầu đổi quà',
  icon: ButtonPressIcon,
  permissions: ['manage_redeem_requests'],
  // Quyền cần để truy cập mục con này
  badge: <Badge tone="magic">---</Badge>
}] : [])];
const marketingMenus: any[] = [{
  url: '/activity',
  label: 'Hoạt động',
  icon: ConnectIcon,
  permissions: ['view_activities', 'manage_activities'] // Quyền cần để truy cập mục này
}, {
  url: '/user_activity',
  label: 'Tương tác',
  icon: TargetIcon,
  permissions: ['manage_user_interactions'],
  // Quyền cần để truy cập mục này
  subNavigationItems: [{
    url: '/user_activity',
    label: 'Các tương tác',
    icon: MenuHorizontalIcon,
    permissions: ['view_interactions'] // Quyền cần để truy cập mục con này
  }]
}];
const advanceUserItems = [{
  url: '/banner',
  label: 'Banner',
  icon: CursorBannerIcon,
  permissions: ['manage_banners'] // Quyền cần để truy cập mục này
}, {
  url: '/comment',
  label: 'Comments',
  icon: SmileyJoyIcon,
  permissions: ['manage_comments'] // Quyền cần để truy cập mục này
}, {
  url: '/review',
  label: 'Đánh giá sản phẩm',
  icon: StarIcon,
  permissions: ['manage_reviews', 'view_reviews', 'edit_reviews'] // Quyền cần để truy cập mục này
}, {
  url: '/notification',
  label: 'Thông báo',
  icon: NotificationIcon,
  permissions: ['manage_notifications'] // Quyền cần để truy cập mục này
}, {
  url: '/webmail',
  label: 'Webmail',
  icon: EmailIcon,
  permissions: ['access_webmail'],
  // Quyền cần để truy cập mục này
  subNavigationItems: [{
    url: '/webmail',
    icon: EmailFollowUpIcon,
    label: 'Sent',
    permissions: ['view_sent_webmail'] // Quyền cần để truy cập mục con này
  }, {
    url: '/webmail/incoming',
    icon: EmailNewsletterIcon,
    label: 'Incoming',
    permissions: ['view_incoming_webmail'] // Quyền cần để truy cập mục con này
  }]
}, {
  url: '/posts',
  label: 'Bài đăng và trang',
  icon: BlogIcon,
  permissions: ['manage_posts', 'edit_posts'],
  // Quyền cần để truy cập mục này
  subNavigationItems: [{
    url: '/posts',
    label: 'Danh sách bài đăng',
    permissions: ['manage_posts', 'edit_posts'] // Quyền cần để truy cập mục này
  }, {
    url: '/posts/category',
    label: 'Chuyên mục',
    permissions: ['manage_post_categories'] // Quyền cần để truy cập mục con này
  }]
}, {
  url: '/media',
  label: 'Media',
  icon: FolderIcon,
  permissions: ['manage_media'] // Quyền cần để truy cập mục này
}];
const gamificationMenu = [{
  url: '/game',
  label: 'Quản lý game',
  icon: GamesIcon,
  badge: <Badge tone="critical">hot</Badge>,
  permissions: ['manage_games'],
  // Quyền cần để truy cập mục này
  subNavigationItems: [{
    url: '/game/checkin',
    label: 'Điểm danh',
    permissions: ['manage_games'] // Quyền cần để truy cập mục con này
  }, {
    url: '/game/lucky_wheel',
    label: 'Vòng quay may mắn',
    permissions: ['manage_games'] // Quyền cần để truy cập mục con này
  }]
}, {
  url: '/achievement',
  label: 'Thành tích',
  icon: StarFilledIcon,
  permissions: ['manage_achievements'] // Quyền cần để truy cập mục này
}, {
  url: '/rank',
  label: 'Xếp hạng',
  icon: MetaobjectIcon,
  permissions: ['manage_ranks'],
  // Quyền cần để truy cập mục này
  subNavigationItems: [{
    url: '/rank',
    label: 'Các hạng',
    icon: MenuHorizontalIcon,
    permissions: [] // Quyền cần để truy cập mục con này
  }, {
    url: '/activity_point',
    label: 'Hành động & Điểm',
    icon: MenuHorizontalIcon,
    permissions: [] // Quyền cần để truy cập mục con này
  }]
}];

/**
 * For admin
 */
let adminMenuItems = [{
  url: '/analytics',
  label: 'Báo cáo',
  icon: ChartVerticalFilledIcon,
  subNavigationItems: [{
    url: '/analytics/customer',
    label: 'Báo cáo khách hàng'
  }, {
    url: '/analytics/order',
    label: 'Báo cáo đơn hàng'
  }, {
    url: '/analytics/product',
    label: 'Báo cáo sản phẩm'
  }, {
    url: '/analytics/staff',
    label: 'Hiệu năng nhân viên'
  }]
}, {
  url: '/devices',
  label: 'Thiết bị',
  icon: MobileIcon,
  permissions: ['manage_devices'],
  // Quyền cần để truy cập mục này
  subNavigationItems: [{
    url: '/devices',
    icon: MobileIcon,
    label: 'Danh sách thiết bị',
    permissions: ['manage_devices'] // Quyền cần để truy cập mục con này
  }
  // {
  //   url: '/devices/referrer_links',
  //   icon: LinkIcon,
  //   label: 'Truy cập liên kết',
  //   permissions: ['manage_devices'], // Quyền cần để truy cập mục con này
  // },
  ]
}, {
  url: '/incoming_webhook',
  label: 'Incoming Webhook',
  icon: IncomingIcon
}, {
  url: '/outgoing_webhook',
  label: 'Outgoing Webhook',
  icon: PackageIcon
}, {
  url: '/settings/',
  label: 'Cài đặt chung',
  icon: SettingsIcon,
  subNavigationItems: [{
    url: '/settings',
    icon: MenuHorizontalIcon,
    label: 'System'
  },
  // { url: '/account_setting', icon: MenuHorizontalIcon, label: 'Tài khoản' },
  {
    url: '/backup',
    icon: MenuHorizontalIcon,
    label: 'Back up'
  }, {
    url: '/embed',
    icon: MenuHorizontalIcon,
    label: 'Mã nhúng & kết nối'
  }, {
    url: '/product_setting',
    icon: MenuHorizontalIcon,
    label: 'Sản phẩm'
  }, {
    url: '/order_setting',
    icon: MenuHorizontalIcon,
    label: 'Đơn hàng'
  }, {
    url: '/review_setting',
    icon: MenuHorizontalIcon,
    label: 'Review'
  }, {
    url: '/media_setting',
    icon: MenuHorizontalIcon,
    label: 'Media'
  }, {
    url: '/keymap',
    icon: MenuHorizontalIcon,
    label: 'Keymap'
  }, {
    url: '/server_info',
    icon: MenuHorizontalIcon,
    label: 'Server info'
  }]
}, {
  url: '/app',
  label: 'SSO',
  icon: AppsIcon
}, {
  url: '/email_setting/',
  label: 'Cài đặt Email',
  icon: EmailIcon,
  subNavigationItems: [{
    url: '/email_setting',
    icon: MenuHorizontalIcon,
    label: 'Tài khoản'
  }, {
    url: '/email_template',
    icon: MenuHorizontalIcon,
    label: 'Biểu mẫu'
  }]
}, {
  url: '/security/',
  label: 'Bảo mật',
  icon: LockFilledIcon
}, {
  url: '/users/',
  label: 'Người dùng',
  icon: PersonIcon,
  subNavigationItems: [{
    url: '/users',
    icon: MenuHorizontalIcon,
    label: 'Tất cả tài khoản'
  }, {
    url: '/user_group',
    icon: MenuHorizontalIcon,
    label: 'Nhóm người dùng'
  }]
}];
if (!HIDE_AFFILIATE_MODE) {
  adminMenuItems = [...adminMenuItems, ...[{
    url: '/affiliate/setup',
    label: 'Cài đặt Affiliate',
    icon: AffiliateIcon,
    subNavigationItems: [{
      url: '/affiliate/setup',
      label: 'Cài đặt chung'
    }, {
      url: '/affiliate/default_setup',
      label: 'Cài đặt mặc định'
    }, {
      url: '/affiliate/advance_setup',
      label: 'Affiliate nâng cao'
    }]
  }, {
    url: '/wallet/',
    label: 'Cài đặt ví',
    icon: WalletIcon
  }]];
}

/**
 * START ...
 * @returns
 */
export default function Sidebar() {
  const location = useLocation();
  const version = process.env.REACT_APP_VERSION;
  const polarisVersion = packageInformation['dependencies']['@shopify/polaris'] || '';
  const {
    settings
  } = useSettings();
  let supporterMenus1 = [...supporterMenus, ...(settings?.group_income_calculator_turnon === '1' && !HIDE_AFFILIATE_MODE ? [{
    url: '/group_income',
    label: 'Doanh thu đội nhóm',
    icon: CalculatorIcon,
    permissions: ['manage_group_income'],
    subNavigationItems: [{
      url: '/group_income',
      label: 'Danh sách'
    }
    // { url: '/group_income/setup', label: 'Cài đặt' },
    ]
  }] : [])];
  const {
    user: current_user_data
  } = useAuth();
  const {
    capacities,
    user_role
  } = current_user_data;

  /** Kiểm tra nếu người dùng hiện tại có quyền */
  const isAllowed = (current_user_can: string): boolean => {
    if (current_user_can === '') return true; // khi không yêu cầu quyền gì ...
    switch (user_role) {
      case 'super_admin':
        return true;
      case 'admin':
        return true;
      default:
        if (user_role === current_user_can) {
          return true;
        } else {
          if (__helpers.isObject(capacities)) for (let key in capacities) {
            let value = capacities[key];
            if (Array.isArray(value) && value.includes(current_user_can)) {
              return true;
            }
          }
        }
        break;
    }
  };

  /**
   * capacities có dữ liệu dạng
   {
    tên nhóm quyền : [các quyền]
   },...
   */
  const filterMenusByPermission = (menus: any[]) => {
    return menus.map(menu => {
      const hasPermission = helpers.isEmpty(menu.permissions) ? true : menu.permissions.some((permission: any) => isAllowed(permission));
      if (!hasPermission) return null;

      // Lọc subNavigationItems nếu có
      const subNavigationItems = menu.subNavigationItems?.filter((subMenu: any) => helpers.isEmpty(subMenu.permissions) ? true : subMenu.permissions.some((permission: any) => isAllowed(permission)));
      return {
        ...menu,
        subNavigationItems // Gán lại subNavigationItems sau khi lọc
      };
    }).filter(Boolean); // Loại bỏ các phần tử null
  };

  // Sử dụng hàm
  const _check_permission_generalMenu = filterMenusByPermission(generalMenu);
  const _check_permission_saleMenus = filterMenusByPermission(saleMenus);
  const _check_permission_stockMenus = filterMenusByPermission(stockMenus);
  const _check_permission_supporterMenus = filterMenusByPermission(supporterMenus1);
  const _check_permission_marketingMenus = filterMenusByPermission(marketingMenus);
  const _check_permission_advanceUserItems = filterMenusByPermission(advanceUserItems);
  const _check_permission_gamificationMenu = filterMenusByPermission(gamificationMenu);
  return <Navigation location={location.pathname}>
      <Navigation.Section items={_check_permission_generalMenu} />
      {!helpers.isEmpty(_check_permission_saleMenus) && <Navigation.Section title="Dành cho Sale" separator items={_check_permission_saleMenus} />}
      {!helpers.isEmpty(_check_permission_stockMenus) && <Navigation.Section title="Quản lý kho hàng" separator items={_check_permission_stockMenus} />}
      {!helpers.isEmpty(_check_permission_supporterMenus) && <Navigation.Section title="Dành cho Supporter" separator items={_check_permission_supporterMenus} />}

      {!helpers.isEmpty(_check_permission_marketingMenus) && <Navigation.Section title="Dành cho Marketing" separator items={_check_permission_marketingMenus} />}

      {!helpers.isEmpty(_check_permission_advanceUserItems) && <Navigation.Section title="Nâng cao" separator items={_check_permission_advanceUserItems} />}

      {!helpers.isEmpty(_check_permission_advanceUserItems) && <Navigation.Section title="Gamification" separator items={_check_permission_gamificationMenu} />}
      <Capacity current_user_can="admin">
        <Navigation.Section title="Quản trị CRM" separator items={adminMenuItems} />
      </Capacity>

      <Navigation.Section fill items={[]} />

      <BlockStack align="center" inlineAlign="center" gap={'200'}>
        <Text as="p" variant="bodyXs" tone="subdued">
          NBEE CRM &copy;2021, version {version}
          <br />
          build: {packageInformation.build_version}
          <br />
          {helpers.isDev() && `Polaris Framework ver ${polarisVersion}`}
        </Text>
        <div style={{
        marginBottom: 5,
        marginTop: 5
      }}></div>
      </BlockStack>
    </Navigation>;
}