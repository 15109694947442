import { Box, Form, Card, Divider, FormLayout, InlineGrid, Select, Text, TextField, BlockStack, useBreakpoints, Checkbox, ContextualSaveBar } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import helpers from 'helpers';
import diachinh from 'config/diachinh.json';
import MultiJob from 'components/multiJob';
import { lengthLessThan, lengthMoreThan, notEmptyString, useField, useForm } from '@shopify/react-form';
import { TypedUser } from 'interface/user.model';
import DateTimeInput from 'components/dateTimeInput';
import __ from 'languages/index';
import { useAddUserToJob } from 'queries/user.query';
import { useUpdateUser } from 'queries/users.query';
import { useNotification } from 'context/NotificationContext';
import { useUserRoleContext } from 'context/UserRoleContext';

/**
 *   Create upload Modal for Notification
 */
export default function UserEditInformation({
  onClose,
  entity
}: {
  onClose: Function;
  entity?: TypedUser;
}) {
  const HIDE_AFFILIATE_MODE = process.env.REACT_APP_HIDE_AFFILIATE === 'true';
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateUser();
  const {
    mutateAsync: addUserToJob
  } = useAddUserToJob();
  const {
    smUp
  } = useBreakpoints();
  const userJobCallback = useCallback((userJobList: string[]) => {
    addUserToJob({
      user_id: entity?.user_id,
      job_id: userJobList ? userJobList.join(',') : null
    });
  }, [entity]);

  /** Lấy user role ra ... */
  const {
    allRoleSelection: allRoles
  } = useUserRoleContext();

  /** Hết code lấy user role ra ... */

  const useFields = {
    user_login: useField<string>({
      value: entity?.user_login || '',
      validates: [lengthLessThan(60, 'Không được dài hơn 60 ký tự.'), lengthMoreThan(2, 'Không được ngắn hơn 2 ký tự.'), inputValue => {
        if (inputValue.length > 0) {
          if (helpers.isUTF8(inputValue)) {
            return 'Không được dùng mã Unicode trong trường này!';
          }
          if (/^[a-zA-Z0-9_.]+$/.exec(inputValue) === null) {
            return 'Chỉ sử dụng chữ cái, số và dấu chấm';
          }
        }
      }]
    }),
    referrer_code: useField<string>({
      value: entity.referrer_code,
      validates: [notEmptyString('Không được để trống trường này.'), inputValue => {
        if (inputValue && inputValue.length > 0) {
          if (helpers.isUTF8(inputValue)) {
            return 'Không được dùng mã Unicode trong trường này!';
          }
          if (/^[a-zA-Z0-9_.]+$/.exec(inputValue) === null) {
            return 'Chỉ sử dụng chữ cái, số và dấu gạch dưới.';
          }
          if (inputValue.length > 12) {
            return 'Không được dài hơn 12 ký tự.';
          }
        }
      }]
    }),
    user_email: useField<string>({
      value: entity?.user_email,
      validates: [inputValue => {
        if (inputValue && !helpers.isEmail(inputValue)) {
          return 'Your email is not valid!';
        }
      }]
    }),
    display_name: useField<string>({
      value: entity?.display_name || '',
      validates: [lengthLessThan(60, 'Tên của bạn quá dài!'), lengthMoreThan(2, 'Tên của bạn quá ngắn!')]
    }),
    bio: useField<string>({
      value: entity?.bio || '',
      validates: [lengthLessThan(250, 'Mô tả quá dài!')]
    }),
    user_address: useField<string>({
      value: entity?.user_address || '',
      validates: [lengthLessThan(250, 'Địa chỉ quá dài!')]
    }),
    user_birthday: useField<string>({
      value: entity?.user_birthday,
      validates: []
    }),
    user_phonenumber: useField<string>({
      value: entity?.user_phonenumber,
      validates: [inputValue => {
        if (inputValue) {
          if (!new RegExp('^[0-9]+$', 'g').test(inputValue)) {
            return 'Định dạng số điện thoại không hợp lệ. Chỉ dùng số và dấu cách!';
          }
        }
      }]
    }),
    user_status: useField<number>({
      value: entity?.user_status || 0,
      validates: []
    }),
    user_role: useField<string>({
      value: entity?.user_role || 'visitor',
      validates: []
    }),
    user_title: useField<string>({
      value: entity?.user_title,
      validates: []
    }),
    user_address_city: useField<string>({
      value: entity?.user_address_city ?? '',
      validates: []
    }),
    user_address_district: useField<string>({
      value: entity?.user_address_district ?? '',
      validates: []
    }),
    user_address_ward: useField<string>({
      value: entity?.user_address_ward ?? '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          user_id: entity?.user_id,
          referrer_code: values?.referrer_code,
          user_status: values.user_status,
          user_role: values.user_role,
          user_login: values.user_login,
          display_name: values.display_name,
          bio: values.bio,
          user_title: values.user_title,
          user_address: values.user_address,
          user_address_city: values.user_address_city,
          user_address_district: values.user_address_district,
          user_address_ward: values.user_address_ward,
          user_phonenumber: values.user_phonenumber,
          user_birthday: values.user_birthday || undefined
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success',
        errors: []
      };
    }
  });
  const {
    addNotification
  } = useNotification();
  useEffect(() => {
    if (updateSuccess) {
      addNotification('info', __('update_successfully'));
      resetForm();
      onClose();
    }
  }, [updateSuccess]);

  /**
   * Địa chỉ
   */

  const user_address_city_selectType = useFields.user_address_city;
  const user_address_district_selectType = useFields.user_address_district;
  const user_address_ward_selectType = useFields.user_address_ward;

  /**
   * Địa chính
   * Chọn tỉnh, thành phố ...
   */
  const [diachinhCity, setDiachinhCity] = useState<string | undefined>(undefined);
  const [diachinhDistrict, setDiachinhDistrict] = useState<string | undefined>(undefined);
  const [diachinhDistrictList, setDiachinhDistrictList] = useState(undefined);
  const [diachinhWards, setDiachinhWards] = useState<string | undefined>(undefined);
  const [diachinhWardsList, setDiachinhWardsList] = useState(undefined);

  // set default value
  useEffect(() => {
    setDiachinhCity(entity?.user_address_city || undefined);
    setDiachinhDistrict(entity?.user_address_district || undefined);
    setDiachinhWards(entity?.user_address_ward || undefined);
  }, [entity]);
  const diachinhCityCallback = useCallback((_value: string) => {
    setDiachinhCity(_value);
    user_address_city_selectType.onChange(_value);
  }, []);
  const [diachinhCityList, setDiachinhCityList] = useState(null);
  useEffect(() => {
    let citys = [{
      label: 'Chọn Tỉnh/thành phố',
      value: ''
    }];
    for (let _city in diachinh) {
      let city = diachinh[_city];
      // @ts-ignore
      citys.push({
        label: city.name,
        value: city.code
      });
    }
    setDiachinhCityList(citys.sort((a, b) => a.label.localeCompare(b.label)));
  }, []);

  /**
   * Địa chính
   * Chọn Quận Huyện
   */
  const diachinhDistrictCallback = useCallback((_value: string) => {
    setDiachinhDistrict(_value);
    user_address_district_selectType.onChange(_value);
  }, []);
  useEffect(() => {
    if (!diachinhCity) {
      return;
    }

    // setDiachinhDistrict(undefined);
    setDiachinhDistrictList(undefined);
    // setDiachinhWards(undefined);
    setDiachinhWardsList(undefined);
    let quanhuyens = [{
      label: 'Chọn Quận/ Huyện',
      value: ''
    }];
    for (let quanhuyen in diachinh[diachinhCity]?.['quan-huyen']) {
      let quanhuyendata = diachinh[diachinhCity]?.['quan-huyen']?.[quanhuyen];
      quanhuyens.push({
        label: quanhuyendata.name_with_type,
        value: quanhuyendata.code
      });
    }
    setDiachinhDistrictList(quanhuyens.sort((a, b) => a.label.localeCompare(b.label)));
  }, [diachinhCity]);

  /**
   * Địa chính
   * Chọn xã Phường ...
   */
  const diachinhWardsCallback = useCallback((_value: string) => {
    setDiachinhWards(_value);
    user_address_ward_selectType.onChange(_value);
  }, []);
  useEffect(() => {
    if (!diachinhCity || !diachinhDistrict) {
      setDiachinhWardsList(undefined);
      return;
    }
    if (typeof diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict] === 'undefined') {
      return;
    }
    let xathitrans = [{
      label: 'Chọn Xã/ Thị trấn',
      value: ''
    }];
    for (let xathitran in diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict]?.['xa-phuong']) {
      // @ts-ignore
      let quanhuyendata = diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict]?.['xa-phuong']?.[xathitran];
      // let city = diachinh[_city];
      // @ts-ignore
      xathitrans.push({
        label: quanhuyendata.name_with_type,
        value: quanhuyendata.code
      });
    }
    setDiachinhWardsList(xathitrans.sort((a, b) => a.label.localeCompare(b.label)));
  }, [diachinhDistrict, diachinhCity]);
  return <Form onSubmit={submit}>
      <BlockStack gap={{
      xs: '800',
      sm: '400'
    }}>
        <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
          <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
            <BlockStack gap="400">
              <Text as="h3" variant="headingMd">
                Tùy chọn
              </Text>
              <Text as="p" variant="bodyMd">
                Các tùy chọn
              </Text>
            </BlockStack>
          </Box>
          <Card roundedAbove="sm">
            <BlockStack gap="400">
              <FormLayout>
                <Checkbox label="Cho phép người dùng đăng nhập" helpText="Nếu tắt, người dùng này không thể đăng nhập nữa." checked={useFields.user_status.value === 1} onChange={e => {
                useFields.user_status.onChange(Number(e));
              }} />

                <Select label="User role" options={allRoles} onChange={selected => {
                useFields.user_role.onChange(selected);
              }} value={useFields.user_role.value || 'visitor'} />
              </FormLayout>
            </BlockStack>
          </Card>
        </InlineGrid>

        {smUp ? <Divider /> : null}

        <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
          <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
            <BlockStack gap="400">
              <Text as="h3" variant="headingMd">
                Thông tin chung
              </Text>
              <Text as="p" variant="bodyMd">
                Càng chi tiết càng tốt.
              </Text>
            </BlockStack>
          </Box>
          <Card roundedAbove="sm">
            <BlockStack gap="400">
              <FormLayout>
                <TextField autoFocus autoComplete="off" label="User name" helpText={<p>User name không được phép trùng lặp với bất kỳ một tài khoản nào!</p>} {...fields.user_login} />
                {!HIDE_AFFILIATE_MODE && <TextField autoComplete="off" label="Mã giới thiệu" maxLength={12} requiredIndicator helpText={<p>Không nên đổi mã nhiều lần, dẫn tới mã cũ bị vô hiệu. Mã này phải độc nhất.</p>} {...fields.referrer_code} />}

                <TextField label="Tên hiển thị" autoComplete="off" {...fields.display_name} connectedLeft={<Select value={useFields.user_title.value || undefined} options={[{
                value: '',
                label: 'Không set'
              }, {
                value: 'mr',
                label: 'Anh'
              }, {
                value: 'miss',
                label: 'Cô'
              }, {
                value: 'mrs',
                label: 'Chị'
              }, {
                value: 'madam',
                label: 'Quý bà'
              }, {
                value: 'sir',
                label: 'Quý ông'
              }]} onChange={e => useFields.user_title.onChange(e)} label={''} />} />

                <DateTimeInput onDateChange={({
                day,
                month,
                year
              }) => {
                fields.user_birthday.onChange(`${year}-${month}-${day}`);
              }} label={'Sinh nhật'} initialDate={fields.user_birthday.defaultValue} />

                <MultiJob onClose={userJobCallback} current_value={entity?.user_to_job} />

                <TextField maxLength={250} max={250} showCharacterCount={true} autoComplete="off" label="Giới thiệu ngắn" {...fields.bio} multiline={3} />
              </FormLayout>
            </BlockStack>
          </Card>
        </InlineGrid>

        {smUp ? <Divider /> : null}
        <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
          <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
            <BlockStack gap="400">
              <Text as="h3" variant="headingMd">
                Thông tin liên hệ
              </Text>
              <Text as="p" variant="bodyMd">
                Càng nhiều thông tin càng tốt
              </Text>
            </BlockStack>
          </Box>
          <Card roundedAbove="sm">
            <BlockStack gap="400">
              <FormLayout>
                <TextField label="Số điện thoại" autoComplete="off" helpText="Ví dụ: 0906111111" {...fields.user_phonenumber} />

                <TextField label="Email" autoComplete="off" helpText="Ví dụ: a@gmail.com" {...fields.user_email} />

                <TextField label="Địa chỉ" autoComplete="off" {...fields.user_address} />

                <FormLayout.Group condensed>
                  <Select key={'ahjfkdgf'} label="Tỉnh/Thành phố" options={diachinhCityList} value={diachinhCity} onChange={diachinhCityCallback} />
                  <Select key={'ahjfdsfsgkdgf'} label="Quận/ huyện" options={diachinhDistrictList} value={diachinhDistrict} onChange={diachinhDistrictCallback} />
                  <Select key={'ahjfksdgsdgsw4dgf'} label="Xã/Thị trấn" options={diachinhWardsList} value={diachinhWards} onChange={diachinhWardsCallback} />
                </FormLayout.Group>
              </FormLayout>
            </BlockStack>
          </Card>
        </InlineGrid>
      </BlockStack>

      <br />
      <br />

      {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
      content: __('update_user_button_label'),
      onAction: () => submit(),
      loading: updating
    }} discardAction={{
      content: 'Thu hồi',
      onAction: () => resetForm()
    }} />}
      <br />
      <br />
      <br />
      <br />
      <br />
    </Form>;
}