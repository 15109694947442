import { useEffect, useRef, useState, useCallback } from 'react';
import { useAuth } from 'context/AuthContext';
import { useSocket } from 'context/SocketContext';
import { Form } from '@shopify/polaris';
import 'media/css/chatGPTWindow.scss';
import helpers from 'helpers/index';
import Markdown from 'react-markdown';
export default function ChatGPT() {
  const {
    user: account
  } = useAuth();
  const {
    socket
  } = useSocket();
  const [loading, setLoading] = useState(true);
  const [isThinking, setIsThinking] = useState(false);
  const [message, setMessage] = useState<{
    user: string;
    assistant: string;
  }[]>([]);
  const [textInput, setTextInput] = useState('');
  const current_ai_thread = useRef('');
  const chatEndRef = useRef<HTMLDivElement>(null); // Tham chiếu để tự động cuộn xuống
  const [ready_load_history, setReady_load_history] = useState(false);

  // gom text từ AI về ...
  const [msg, setMsg] = useState('');

  /** chạy lúc đầu thôi */
  const startAIThread = useCallback(async () => {
    setLoading(true);
    let _current_ai_thread = helpers.cookie_get('ai_thread');
    if (socket && !_current_ai_thread) {
      socket.emit('create_ai_thread', {
        display_name: account?.display_name
      });
      socket.on('create_ai_thread', thread_id => {
        current_ai_thread.current = thread_id;
        helpers.cookie_set('ai_thread', thread_id);
        setLoading(false);
        setReady_load_history(true);
      });
    }
    if (_current_ai_thread) {
      current_ai_thread.current = _current_ai_thread;
      setLoading(false);
      setReady_load_history(true);
    }

    /** Khi lịch sử đổ về */
    socket.on('chat_history', (messageData: {
      user: string;
      assistant: string;
    }[]) => {
      setMessage(messageData);
    });
  }, [socket, account]);

  /** lấy lịch sử trễ chút */
  useEffect(() => {
    if (ready_load_history) {
      socket.emit('chat_history', {
        thread_id: current_ai_thread.current
      });
    }
  }, [ready_load_history, socket]);
  const callWhenDoneThinking = useCallback(async () => {
    if (msg) setMessage(prevMessages => [...prevMessages, {
      user: '',
      assistant: msg
    }]);
    await helpers.sleep(1000);
    setMsg('');
    // focus lại vào chat
    document.getElementById('me_talk_chatGPT')?.focus();
  }, [msg]);
  useEffect(() => {
    if (isThinking === false) {
      callWhenDoneThinking();
    }
  }, [isThinking]);

  /** start and stop all things */
  useEffect(() => {
    startAIThread();
    socket.on('message_from_ai', args => {
      setMsg(prevMessages => prevMessages += args.message);
      chatEndRef.current?.scrollIntoView({
        behavior: 'smooth'
      }); // Tự động cuộn xuống cuối
      setIsThinking(true);
    });

    // khi end thì kết thúc chuỗi
    socket.on('end_message_from_ai', ({
      message
    }) => {
      if (message !== '') {
        setMsg(message);
      }
      setIsThinking(false);
    });
    return () => {
      socket.emit('remove_ai_runner', {
        thread_id: current_ai_thread.current
      });
      socket.off('message_from_ai');
      socket.off('end_message_from_ai');
      socket.off('create_ai_thread');
      socket.off('chat_history');
    };
  }, [socket]);
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({
      behavior: 'smooth'
    }); // Tự động cuộn xuống cuối
  }, [message, chatEndRef]);
  const inputOnChangeCallback = useCallback(e => {
    setTextInput(e.target.value);
  }, []);
  const userSubmitText = useCallback(() => {
    if (!textInput) return;

    // command
    if (String(textInput + ``).toLowerCase() === 'clear') {
      helpers.cookie_delete('ai_thread');
      setMessage([]);
      return startAIThread();
    }
    setMessage(prevMessages => [...prevMessages, {
      user: textInput,
      assistant: ''
    }]);
    setTextInput('');
    setIsThinking(true); // Bật trạng thái "Đang nghĩ..."

    socket.emit('message_to_ai', {
      message: textInput,
      thread_id: current_ai_thread.current,
      instructions: '' // Hướng dẫn hoặc thêm thông tin cho nó, ví dụ như trước khi hỏi về một sản phẩm ...
    });
  }, [textInput, socket, current_ai_thread]);
  return <>
      {loading ? <div style={{
      textAlign: 'center',
      marginTop: '2rem'
    }}>Creating a new thread...</div> : <>
          <div className="chatGPTWrap">
            <div className="chatGPT_thread">
              {message.map((el, index) => <div className={el.user ? 'user' : 'assistant'} key={index}>
                  <Markdown>{el.user || el.assistant}</Markdown>
                </div>)}
              {isThinking && <div className=" assistant thinking">
                  <Markdown>{msg}</Markdown>
                  <span>***</span>
                </div>}
              <div ref={chatEndRef} /> {/* Thẻ rỗng để cuộn xuống cuối */}
            </div>
          </div>
          <Form onSubmit={userSubmitText}>
            <textarea disabled={isThinking || loading} id="me_talk_chatGPT" placeholder="Nhập tại đây (gõ clear để làm sạch nội dung)" value={textInput} autoFocus onChange={inputOnChangeCallback} onInput={(e: any) => {
          e.target.style.height = 'auto';
          e.target.style.height = (Number(e.target.scrollHeight) < 150 ? e.target.scrollHeight : 150) + 'px';
        }} onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Ngăn xuống dòng
            userSubmitText(); // Gửi tin nhắn
          }
        }} style={{
          minHeight: '40px',
          maxHeight: '200px',
          overflowY: 'auto'
        }} />
          </Form>
        </>}
    </>;
}