import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, ContextualSaveBar, Text, InlineGrid, Page, TextField } from '@shopify/polaris';
import { useForm, useField } from '@shopify/react-form';
import { useUpdateSetting } from 'queries/setting.query';
import { useSettings } from 'context/SettingsContext';
import { useNotification } from 'context/NotificationContext';
import __ from 'languages/index';
import { PlusIcon, DeleteIcon } from '@shopify/polaris-icons';
export default function GroupIncomeSetup() {
  const {
    addNotification
  } = useNotification();
  const {
    settings: entities
  } = useSettings();
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateSetting();
  const initialRanks = useMemo(() => [{
    rank: 'NPP1',
    diamonds: '5000000',
    rate: '15'
  }, {
    rank: 'NPP2',
    diamonds: '30000000',
    rate: '21'
  }, {
    rank: 'NPP3',
    diamonds: '70000000',
    rate: '27'
  }, {
    rank: 'Leader',
    diamonds: '71000000',
    rate: '27'
  }], []);
  const useFields = {
    rank_settings: useField<{
      rank: string;
      diamonds: string;
      rate: string;
    }[]>({
      value: entities?.rank_settings ?? initialRanks,
      validates: []
    })
  };
  const {
    submit,
    submitting,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          rank_settings: values.rank_settings
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    if (updateSuccess) addNotification('info', __('updated_successfully'));
  }, [updateSuccess]);
  const addNewRuleToProductCommissionCallback = useCallback(() => {
    let currentValue = [...useFields.rank_settings.value, ...[]];
    if (currentValue.length > 6) return;
    currentValue.push({
      rank: 'Chưa đặt tên',
      diamonds: '1',
      rate: '1'
    });
    useFields.rank_settings.onChange(currentValue);
  }, [useFields.rank_settings]);
  const deleteRuleToProductCommissionCallback = useCallback((index_id: number) => {
    let currentValue = [...useFields.rank_settings.value, ...[]];
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.rank_settings.onChange(currentValue);
  }, [useFields.rank_settings]);
  const {
    rank_settings
  } = useFields;
  return <>
      <Page>
        {rank_settings.value.map((commission, index) => {
        return <div key={'index_group_income_' + index}>
              <InlineGrid columns={{
            xs: 1,
            md: '3'
          }}>
                <Text as="p">{`-- Tên Rank`}</Text>
                <Text as="p">{`-- Kim cương`}</Text>
                <Text as="p">{`-- Tỉ lệ thưởng`}</Text>
              </InlineGrid>
              <InlineGrid columns={{
            xs: 1,
            md: '3'
          }}>
                <TextField autoComplete="off" label={''} value={commission.rank.toString()} onChange={value => rank_settings.onChange(rank_settings.value.map((item, i) => i === index ? {
              ...item,
              rank: value
            } : item))} />
                <TextField autoComplete="off" label={``} value={commission.diamonds.toString()} onChange={value => rank_settings.onChange(rank_settings.value.map((item, i) => i === index ? {
              ...item,
              diamonds: value
            } : item))} />
                <TextField autoComplete="off" label={``} value={commission.rate.toString()} onChange={value => rank_settings.onChange(rank_settings.value.map((item, i) => i === index ? {
              ...item,
              rate: value
            } : item))} connectedRight={<Button onClick={() => deleteRuleToProductCommissionCallback(index)} variant="plain" icon={DeleteIcon}></Button>} />
              </InlineGrid>
            </div>;
      })}

        <Button icon={PlusIcon} onClick={() => addNewRuleToProductCommissionCallback()} variant="primary">
          Thêm quy tắc
        </Button>
      </Page>

      {dirty && <ContextualSaveBar message="Thay đổi của bạn chưa được lưu." saveAction={{
      onAction: submit,
      loading: updating,
      disabled: !dirty
    }} discardAction={{
      content: 'Hoàn tác',
      onAction: Userreset
    }} />}
    </>;
}