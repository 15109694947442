import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import List from './list';
import Setup from './setup';
export default function GiftIndex() {
  let {
    slug = 'list'
  } = useParams();
  switch (slug) {
    case 'list':
      return <List />;
    case 'setup':
      return <Setup />;
    default:
      return <Theme404 />;
  }
}